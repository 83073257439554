<template>
  <div id="main">
    <div class="content" v-if='type=="wrightinTwo"'>
      <p class="title">盆底肌修复仪（芝兰玉叶）售后管理制度</p>
      <p class="subTitle mt16">一、质保期</p>
      <p class="section mt16">
        1）产品主机自激活日起1年内出现非人为质量问题由我司负责包修服务。
        <br />
        2）产品在质保期内进行过换货处理的自换货之日起重新计算质保期。
        <br />
        3）主机配件无法拆机维修，故质保期1年内，若因非人为故障导致无法维修，给予换货。
      </p>
      <p class="section mt16">产品的其他配件，如磁吸充电线、腹部电极连接线等，质保期如下表所示：</p>
      <div class="table">
        <span class="table-title">盆底肌修复仪配件质保期</span>
        <span class="table-row">
                    <span style="width: 33%;">序号</span>
                    <span style="width: 33%;">名称</span>
                    <span style="width: 33%;">质保期</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">1</span>
                    <span style="width: 33%;">磁吸充电线</span>
                    <span style="width: 33%;">3个月</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">2</span>
                    <span style="width: 33%;">腹部电极连接线</span>
                    <span style="width: 33%;">3个月</span>
                </span>
      </div>
      <p class="section mt16">理疗电极片属于耗材，不在质保范围内。</p>
      <p class="thirdTitle mt16">下列情况不属于质保范围，因以下行为产生的故障实行收费维修：</p>
      <p class="section mt8">
        1）超出保修有效期；
        <br />
        2）产品受外力导致损伤；
        <br />
        3）故意行为造成的损坏；
        <br />
        4）使用不当造成的故障或损坏；
        <br />
        5）私自拆、装、修等造成的损坏；
        <br />
        6）未按产品使用说明书要求使用、维护、保养而造成的损坏；
        <br />
        7）不可抗拒外力（如水灾、火灾、地震、雷击等强烈震动或挤压）等因素造成的物理损坏及故障，如摔坏、压坏、变形等情况。
      </p>
      <p class="subTitle mt32">二、延保服务</p>
      <p class="section mt16">
        1）产品可在激活日起7天内补充购买延保服务，通过线上渠道购买产品可同时一并购买延保服务，购买延保服务时需提供产品编号。
        <br />
        2）延保服务是在产品质保期1年的基础上，从产品原有质保期结束次日起开始计算，主机延长半年或1年（具体以您所购买的延保期限为准）保修服务。
        <br />
        3）延保服务仅限主机，不包括理疗电极片、磁吸充电线、腹部电极连接线等配件及赠品。
        <br />
        4）每个产品仅支持购买一次延保服务，不可叠加购买。
        <br />
        5）延保服务为增值服务产品，支付成功立即生效，不支持无理由退款。
        <br />
        6）若延保期内出现换货情况，设备的质保期为更换后的设备自激活日起1年。
      </p>
      <p class="section mt16">产品主机延保服务如下表所示：</p>
      <div class="table">
        <span class="table-title">盆底肌修复仪延保费用清单</span>
        <span class="table-row">
                    <span style="width: 33%;">序号</span>
                    <span style="width: 33%;">延保时长</span>
                    <span style="width: 33%;">价格</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">1</span>
                    <span style="width: 33%;">半年</span>
                    <span style="width: 33%;">188元</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">2</span>
                    <span style="width: 33%;">1年</span>
                    <span style="width: 33%;">288元</span>
                </span>
      </div>
      <p class="thirdTitle mt16">下列情况延保服务失效：</p>
      <p class="section mt8">
        1）超出延保服务有效期；
        <br />
        2）产品受外力导致损伤；
        <br />
        3）故意行为造成的损坏；
        <br />
        4）使用不当造成的故障或损坏；
        <br />
        5）私自拆、装、修等造成的损坏；
        <br />
        6）未按产品使用说明书要求使用、维护、保养而造成的损坏；
        <br />
        7）不可抗拒外力（如水灾、火灾、地震、雷击等强烈震动或挤压）等因素造成的物理损坏及故障，如摔坏、压坏、变形等情况。
      </p>
      <p class="subTitle mt32">三、7天无理由退换货</p>
      <p class="section mt16">
        1）通过澜渟App、澜渟麦豆专卖店、澜渟天猫官方旗舰店、澜渟京东官方旗舰店购买的产品，如出现非人为质量问题，请于自签收日起7天内与澜渟客服或店铺客服联系寄回，我司收到货后检测确认问题属实，且主机包装防撕贴未撕动，主机未激活，给予退换货。（如超时联系或者检测无问题，不予退换货。）
        <br />
        2）通过其他代理渠道购买的产品，请联系代理商办理退换货服务。
      </p>
      <p class="thirdTitle mt16">下列情况不予办理7天无理由退换货服务：</p>
      <p class="section mt8">
        1）超时联系；
        <br />
        2）主机包装防撕贴撕动；
        <br />
        3）主机已激活；
        <br />
        4）主机出现人为质量问题；
        <br />
        5）检测无问题或问题不属实；
        <br />
        6）退回的产品主机序列号与出库序列号不符；
        <br />
        7）产品原外包装不完整，标准配件不齐全；
        <br />
        8）随订单赠送的赠品未能如数退回，赠品包装不完整；
        <br />
        9）购买时页面明确说明不支持退换货的商品。
      </p>
      <p class="subTitle mt32">四、15天换货</p>
      <p class="section mt16">
        1）通过澜渟App、澜渟麦豆专卖店、澜渟天猫官方旗舰店、澜渟京东官方旗舰店购买的产品，如出现非人为质量问题，请于自签收日起15天内与澜渟客服或店铺客服联系寄回，我司收到货后检测确认问题属实，给予换货。（如超时联系或者检测无问题，不予换货。）
        <br />
        2）通过其他代理渠道购买的产品，请联系代理商办理换货服务。
        <br />
        3）产品主机出现非人为质量问题，则更换主机。产品配件出现非人为质量问题，则更换问题配件。
      </p>
      <p class="thirdTitle mt16">下列情况不予办理15天换货服务：</p>
      <p class="section mt8">
        1）超时联系；
        <br />
        2）主机出现人为质量问题；
        <br />
        3）检测无问题或问题不属实；
        <br />
        4）退回的产品主机序列号与出库序列号不符；
        <br />
        5）购买时页面明确说明不支持换货的商品。
      </p>
      <p class="subTitle mt32">五、质保期外更换、人为故障处理</p>
      <p class="section mt16">产品超质保期或者质保期间人为故障导致损坏，我司可付费更换故障配件，详见下图：</p>
      <div class="table">
        <span class="table-title">盆底肌修复仪付费更换项目清单</span>
        <span class="table-row">
                    <span style="width: .8rem;">序号</span>
                    <span style="width: 2.8rem;">名称</span>
                    <span style="width: .8rem">数量</span>
                    <span style="width: .8rem">单位</span>
                    <span style="width: calc(100% - 5.2rem);">价格</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">1</span>
                    <span style="width: 2.8rem;">盆底肌修复仪主机</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">台</span>
                    <span style="width: calc(100% - 5.2rem);">2480元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">2</span>
                    <span style="width: 2.8rem;">腹部电极连接线</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">根</span>
                    <span style="width: calc(100% - 5.2rem);">100元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">3</span>
                    <span style="width: 2.8rem;">理疗电极片</span>
                    <span style="width: .8rem">2</span>
                    <span style="width: .8rem">对</span>
                    <span style="width: calc(100% - 5.2rem);">60元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">4</span>
                    <span style="width: 2.8rem;">磁吸充电线</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">根</span>
                    <span style="width: calc(100% - 5.2rem);">100元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">5</span>
                    <span style="width: 2.8rem;">充电头</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">个</span>
                    <span style="width: calc(100% - 5.2rem);">40元</span>
                </span>
      </div>
      <p class="subTitle mt32">六、运费</p>
      <p class="section mt8">
        1）质保期内，非人为质量问题的退换货、维修：运费由我司承担，由用户寄回时先行垫付，我司收到后给予实际支付金额（最高不超过12元）的赔付，或者给予赠品（润滑剂）补偿。
        <br />
        2）质保期内，寄回产品检测无问题或者人为质量问题的维修：运费由用户自行承担。
        <br />
        3）超质保期：运费由用户自行承担。
      </p>
      <p class="hotline mt32">如有疑问，请咨询客服热线：400-133-5668</p>
      <p class="section mt16">备注：本文档自2021年04月02日开始执行</p>
    </div>
    <div class="content" v-if='type=="wrightin"'>
      <p class="title">盆底生物刺激反馈仪售后管理制度</p>
      <p class="subTitle mt16">一、质保期</p>
      <p class="section mt16">
        1）产品主机自激活日起1年内出现非人为质量问题由我司负责包修服务。
        <br />
        2）产品在质保期内进行过换货处理的自换货之日起重新计算质保期。
        <br />
        3）主机配件无法拆机维修，故质保期1年内，若因非人为故障导致无法维修，给予换货。
      </p>
      <p class="section mt16">产品的其他配件，如充电盒、充电线等，质保期如下表所示：</p>
      <div class="table">
        <span class="table-title">盆底生物刺激反馈仪配件质保期</span>
        <span class="table-row">
                    <span style="width: 33%;">序号</span>
                    <span style="width: 33%;">名称</span>
                    <span style="width: 34%;">质保期</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">1</span>
                    <span style="width: 33%;">充电盒</span>
                    <span style="width: 34%;">1年</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">2</span>
                    <span style="width: 33%;">充电线</span>
                    <span style="width: 34%;">3个月</span>
                </span>
      </div>
      <p class="thirdTitle mt16">下列情况不属于质保范围，因以下行为产生的故障实行收费维修：</p>
      <p class="section mt8">
        1）超出保修有效期；
        <br />
        2）产品受外力导致损伤；
        <br />
        3）故意行为造成的损坏；
        <br />
        4）使用不当造成的故障或损坏；
        <br />
        5）私自拆、装、修等造成的损坏；
        <br />
        6）未按产品使用说明书要求使用、维护、保养而造成的损坏；
        <br />
        7）不可抗拒外力（如水灾、火灾、地震、雷击等强烈震动或挤压）等因素造成的物理损坏及故障，如摔坏、压坏、变形等情况。
      </p>
      <p class="subTitle mt32">二、延保服务</p>
      <p class="section mt16">
        1）产品可在激活日起7天内补充购买延保服务，通过线上渠道购买产品可同时一并购买延保服务，购买延保服务时需提供产品编号。
        <br />
        2）延保服务是在产品质保期1年的基础上，从产品原有质保期结束次日起开始计算，主机延长半年或1年（具体以您所购买的延保期限为准）保修服务。
        <br />
        3）延保服务仅限主机，不包括充电盒、充电线等附件及赠品。
        <br />
        4）每个产品仅支持购买一次延保服务，不可叠加购买。
        <br />
        5）延保服务为增值服务产品，支付成功立即生效，不支持无理由退款。
        <br />
        6）若延保期内出现换货情况，设备的质保期为更换后的设备自激活日起1年。
      </p>
      <p class="section mt16">产品的其他配件，如充电盒、充电线等，质保期如下表所示：</p>
      <div class="table">
        <span class="table-title">盆底生物刺激反馈仪延保费用清单</span>
        <span class="table-row">
                    <span style="width: 33%;">序号</span>
                    <span style="width: 33%;">延保时长</span>
                    <span style="width: 34%;">价格</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">1</span>
                    <span style="width: 33%;">半年</span>
                    <span style="width: 34%;">188元</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">2</span>
                    <span style="width: 33%;">1年</span>
                    <span style="width: 34%;">288元</span>
                </span>
      </div>
      <p class="thirdTitle mt16">下列情况延保服务失效：</p>
      <p class="section mt8">
        1）超出延保服务有效期；
        <br />
        2）产品受外力导致损伤；
        <br />
        3）故意行为造成的损坏；
        <br />
        4）使用不当造成的故障或损坏；
        <br />
        5）私自拆、装、修等造成的损坏；
        <br />
        6）未按产品使用说明书要求使用、维护、保养而造成的损坏；
        <br />
        7）不可抗拒外力（如水灾、火灾、地震、雷击等强烈震动或挤压）等因素造成的物理损坏及故障，如摔坏、压坏、变形等情况。
      </p>
      <p class="subTitle mt32">三、7天无理由退换货</p>
      <p class="section mt16">
        1）通过澜渟App、澜渟麦豆专卖店、澜渟天猫官方旗舰店、澜渟京东官方旗舰店购买的产品，如出现非人为质量问题，请于自签收日起7天内与澜渟客服或店铺客服联系寄回，我司收到货后检测确认问题属实，且主机包装塑封未拆封，主机未激活，给予退换货。（如超时联系或者检测无问题，不予退换货。）
        <br />
        2）通过其他代理渠道购买的产品，请联系代理商办理退换货服务。
      </p>
      <p class="thirdTitle mt16">下列情况不予办理7天无理由退换货服务：</p>
      <p class="section mt8">
        1）超时联系；
        <br />
        2）主机包装塑封膜拆除；
        <br />
        3）主机已激活；
        <br />
        4）主机出现人为质量问题；
        <br />
        5）检测无问题或问题不属实；
        <br />
        6）退回的产品主机序列号与出库序列号不符；
        <br />
        7）产品原外包装不完整，标准配件不齐全；
        <br />
        8）随订单赠送的赠品未能如数退回，赠品包装不完整；
        <br />
        9）购买时页面明确说明不支持退换货的商品。
      </p>
      <p class="subTitle mt32">四、15天换货</p>
      <p class="section mt16">
        1）通过澜渟App、澜渟麦豆专卖店、澜渟天猫官方旗舰店、澜渟京东官方旗舰店购买的产品，如出现非人为质量问题，请于自签收日起15天内与澜渟客服或店铺客服联系寄回，我司收到货后检测确认问题属实，给予换货。（如超时联系或者检测无问题，不予换货。）
        <br />
        2）通过其他代理渠道购买的产品，请联系代理商办理换货服务。
        <br />
        3）产品主机出现非人为质量问题，则更换主机。产品配件出现非人为质量问题，则更换问题配件。
      </p>
      <p class="thirdTitle mt16">下列情况不予办理15天换货服务：</p>
      <p class="section mt8">
        1）超时联系；
        <br />
        2）主机出现人为质量问题；
        <br />
        3）检测无问题或问题不属实；
        <br />
        4）退回的产品主机序列号与出库序列号不符；
        <br />
        5）购买时页面明确说明不支持换货的商品。
      </p>
      <p class="subTitle mt32">五、质保期外更换、人为故障处理</p>
      <p class="section mt16">产品超质保期或者质保期间人为故障导致损坏，我司可付费更换故障配件，详见下图：</p>
      <div class="table">
        <span class="table-title">盆底生物刺激反馈仪付费更换项目清单</span>
        <span class="table-row">
                    <span style="width: .8rem;">序号</span>
                    <span style="width: 2.8rem;">名称</span>
                    <span style="width: .8rem">数量</span>
                    <span style="width: .8rem">单位</span>
                    <span style="width: calc(100% - 5.2rem);">价格</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">1</span>
                    <span style="width: 2.8rem;">盆底生物刺激反馈仪主机</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">台</span>
                    <span style="width: calc(100% - 5.2rem);">1780元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">2</span>
                    <span style="width: 2.8rem;">充电盒</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">个</span>
                    <span style="width: calc(100% - 5.2rem);">680元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">3</span>
                    <span style="width: 2.8rem;">充电线</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">根</span>
                    <span style="width: calc(100% - 5.2rem);">40元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">4</span>
                    <span style="width: 2.8rem;">充电头</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">个</span>
                    <span style="width: calc(100% - 5.2rem);">40元</span>
                </span>
      </div>
      <p class="subTitle mt32">六、运费</p>
      <p class="section mt8">
        1）质保期内，非人为质量问题的退换货、维修：运费由我司承担，由用户寄回时先行垫付，我司收到后给予实际支付金额（最高不超过12元）的赔付，或者给予赠品（润滑剂）补偿。
        <br />
        2）质保期内，寄回产品检测无问题或者人为质量问题的维修：运费由用户自行承担。
        <br />
        3）超质保期：运费由用户自行承担。
      </p>
      <p class="hotline mt32">如有疑问，请咨询客服热线：400-133-5668</p>
      <p class="section mt16">备注：本文档自2021年04月02日开始执行</p>
    </div>
    <div class="content" v-else-if='type=="kegel"'>
      <p class="title">盆底肌肉康复器售后管理制度</p>
      <p class="subTitle mt16">一、质保期</p>
      <p class="section mt16">
        1）产品自收货之日起1年内出现非人为质量问题由我司负责包修服务。
        <br />
        2）收货日期不明确时，按照发票日期或出厂日期延后90天作为质保起始日期。
        <br />
        3）产品在质保期内进行过换货处理的自换货之日起重新计算质保期。
        <br />
        4）产品无法拆机维修，故质保期1年内，若因非人为故障导致无法维修，给予换货。
      </p>
      <p class="thirdTitle mt16">下列情况不属于质保范围，因以下行为产生的故障实行收费维修：</p>
      <p class="section mt8">
        1）超出保修有效期；
        <br />
        2）产品受外力导致损伤；
        <br />
        3）故意行为造成的损坏；
        <br />
        4）使用不当造成的故障或损坏；
        <br />
        5）私自拆、装、修等造成的损坏；
        <br />
        6）未按产品使用说明书要求使用、维护、保养而造成的损坏；
        <br />
        7）不可抗拒外力（如水灾、火灾、地震、雷击等强烈震动或挤压）等因素造成的物理损坏及故障，如摔坏、压坏、变形等情况；
        <br />
        8）产品外包装盒损坏。
      </p>
      <p class="subTitle mt16">二、7天无理由退换货</p>
      <p class="section mt16">
        1）通过澜渟App、澜渟麦豆专卖店、澜渟天猫官方旗舰店、澜渟京东官方旗舰店购买的产品，如出现非人为质量问题，请于自签收日起7天内与澜渟客服或店铺客服联系寄回，我司收到货后检测确认问题属实，且产品包装塑封未拆封，给予退换货。（如超时联系或者检测无问题，不予退换货。）
        <br />
        2）通过其他代理渠道购买的产品，请联系代理商办理退换货服务。
      </p>
      <p class="thirdTitle mt16">下列情况不予办理7天无理由退换货服务：</p>
      <p class="section mt8">
        1）超时联系；
        <br />
        2）产品包装塑封膜拆除；
        <br />
        3）产品出现人为质量问题；
        <br />
        4）检测无问题或问题不属实；
        <br />
        5）产品原外包装不完整；
        <br />
        6）随订单赠送的赠品未能如数退回，赠品包装不完整；
        <br />
        7）购买时页面明确说明不支持退换货的商品。
      </p>
      <p class="subTitle mt16">三、15天换货</p>
      <p class="section mt16">
        1）通过澜渟App、澜渟麦豆专卖店、澜渟天猫官方旗舰店、澜渟京东官方旗舰店购买的产品，如出现非人为质量问题，请于自签收日起15天内与澜渟客服或店铺客服联系寄回，我司收到货后检测确认问题属实，给予换货。（如超时联系或者检测无问题，不予换货。）
        <br />
        2）通过其他代理渠道购买的产品，请联系代理商办理换货服务。
      </p>
      <p class="thirdTitle mt16">下列情况不予办理15天换货服务：</p>
      <p class="section mt8">
        1）超时联系；
        <br />
        2）产品出现人为质量问题；
        <br />
        3）检测无问题或问题不属实；
        <br />
        4）购买时页面明确说明不支持换货的商品。
      </p>
      <p class="subTitle mt16">四、质保期外更换、人为故障处理</p>
      <p class="section mt16">
        产品超质保期或者质保期间人为故障导致损坏，我司不予处理。
      </p>
      <p class="subTitle mt16">五、运费</p>
      <p class="section mt16">
        1）质保期内，非人为质量问题的退换货：运费由我司承担，由用户寄回时先行垫付，我司收到后给予实际支付金额（最高不超过12元）的赔付，或者给予赠品（润滑剂）补偿。
      </p>
      <p class="hotline mt32">如有疑问，请咨询客服热线：400-133-5668</p>
      <p class="section mt16">备注：本文档自2021年04月02日开始执行</p>
    </div>
    <div class="content" v-else-if='type=="wireless"'>
      <p class="title">无线产后恢复仪售后管理制度</p>
      <p class="subTitle mt16">一、质保期</p>
      <p class="section mt16">
        1）产品主机自激活日起1年内出现非人为质量问题由我司负责包修服务。
        <br />
        2）产品在质保期内进行过换货处理的自换货之日起重新计算质保期。
        <br />
        3）主机配件无法拆机维修，故质保期1年内，若因非人为故障导致无法维修，给予换货。
      </p>
      <p class="section mt16">产品的其他配件，如充电线，质保期如下表所示：</p>
      <div class="table">
        <span class="table-title">无线产后恢复仪配件质保期</span>
        <span class="table-row">
                    <span style="width: 33%;">序号</span>
                    <span style="width: 33%;">名称</span>
                    <span style="width: 34%;">质保期</span>
                </span>
        <span class="table-row">
                    <span style="width: 33%;">1</span>
                    <span style="width: 33%;">充电线</span>
                    <span style="width: 34%;">3个月</span>
                </span>
      </div>
      <p class="section mt16">电极片属于耗材，不在质保范围内。</p>
      <p class="thirdTitle mt16">下列情况不属于质保范围，因以下行为产生的故障实行收费维修：</p>
      <p class="section mt8">
        1）超出保修有效期；
        <br />
        2）产品受外力导致损伤；
        <br />
        3）故意行为造成的损坏；
        <br />
        4）使用不当造成的故障或损坏；
        <br />
        5）私自拆、装、修等造成的损坏；
        <br />
        6）未按产品使用说明书要求使用、维护、保养而造成的损坏；
        <br />
        7）不可抗拒外力（如水灾、火灾、地震、雷击等强烈震动或挤压）等因素造成的物理损坏及故障，如摔坏、压坏、变形等情况。
      </p>
      <p class="subTitle mt16">二、7天无理由退换货</p>
      <p class="section mt16">
        1）通过澜渟App、澜渟麦豆专卖店、澜渟天猫官方旗舰店、澜渟京东官方旗舰店购买的产品，如出现非人为质量问题，请于自签收日起7天内与澜渟客服或店铺客服联系寄回，我司收到货后检测确认问题属实，且主机及配件包装完好，主机未激活，给予退换货。（如超时联系或者检测无问题，不予退换货。）
        <br />
        2）通过其他代理渠道购买的产品，请联系代理商办理退换货服务。
      </p>
      <p class="thirdTitle mt16">下列情况不予办理7天无理由退换货服务：</p>
      <p class="section mt8">
        1）超时联系；
        <br />
        2）主机包装损坏或缺失；
        <br />
        3）主机已激活；
        <br />
        4）主机出现人为质量问题；
        <br />
        5）检测无问题或问题不属实；
        <br />
        6）退回的产品主机序列号与出库序列号不符；
        <br />
        7）产品原外包装不完整，标准配件不齐全；
        <br />
        8）随订单赠送的赠品未能如数退回，赠品包装不完整；
        <br />
        9）购买时页面明确说明不支持退换货的商品。
      </p>
      <p class="subTitle mt16">三、15天换货</p>
      <p class="section mt16">
        1）通过澜渟App、澜渟麦豆专卖店、澜渟天猫官方旗舰店、澜渟京东官方旗舰店购买的产品，如出现非人为质量问题，请于自签收日起15天内与澜渟客服或店铺客服联系寄回，我司收到货后检测确认问题属实，给予换货。（如超时联系或者检测无问题，不予换货。）
        <br />
        2）通过其他代理渠道购买的产品，请联系代理商办理换货服务。
        <br />
        3）产品主机出现非人为质量问题，则更换主机。产品配件出现非人为质量问题，则更换问题配件。
      </p>
      <p class="thirdTitle mt16">下列情况不予办理15天换货服务：</p>
      <p class="section mt8">
        1）超时联系；
        <br />
        2）主机出现人为质量问题；
        <br />
        3）检测无问题或问题不属实；
        <br />
        4）退回的产品主机序列号与出库序列号不符；
        <br />
        5）购买时页面明确说明不支持换货的商品。
      </p>
      <p class="subTitle mt16">四、质保期外更换、人为故障处理</p>
      <p class="section mt16">产品超质保期或者质保期间人为故障导致损坏，我司可付费更换故障配件，详见下图：</p>
      <div class="table">
        <span class="table-title">无线产后恢复仪付费更换项目清单</span>
        <span class="table-row">
                    <span style="width: .8rem;">序号</span>
                    <span style="width: 2.8rem;">名称</span>
                    <span style="width: .8rem">数量</span>
                    <span style="width: .8rem">单位</span>
                    <span style="width: calc(100% - 5.2rem);">价格</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">1</span>
                    <span style="width: 2.8rem;">无线产后恢复仪主机</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">台</span>
                    <span style="width: calc(100% - 5.2rem);">580元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">2</span>
                    <span style="width: 2.8rem;">乳形电极片</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">对</span>
                    <span style="width: calc(100% - 5.2rem);">158元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">3</span>
                    <span style="width: 2.8rem;">翼形电极片</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">对</span>
                    <span style="width: calc(100% - 5.2rem);">158元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">4</span>
                    <span style="width: 2.8rem;">圆形电极片</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">对</span>
                    <span style="width: calc(100% - 5.2rem);">128元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">5</span>
                    <span style="width: 2.8rem;">充电线</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">根</span>
                    <span style="width: calc(100% - 5.2rem);">40元</span>
                </span>
        <span class="table-row">
                    <span style="width: .8rem;">6</span>
                    <span style="width: 2.8rem;">充电头</span>
                    <span style="width: .8rem">1</span>
                    <span style="width: .8rem">个</span>
                    <span style="width: calc(100% - 5.2rem);">40元</span>
                </span>
      </div>
      <p class="subTitle mt16">五、运费</p>
      <p class="section mt16">
        1）质保期内，非人为质量问题的退换货、维修：运费由我司承担，由用户寄回时先行垫付，我司收到后给予实际支付金额（最高不超过12元）的赔付，或者给予赠品补偿。
        <br />
        2）质保期内，寄回产品检测无问题或者人为质量问题的维修：运费由用户自行承担。
        <br />
        3）超质保期：运费由用户自行承担。
      </p>
      <p class="hotline mt32">如有疑问，请咨询客服热线：400-133-5668</p>
      <p class="section mt16">备注：本文档自2021年04月02日开始执行</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: '',
    }
  },
  mounted() {
    this.type = this.$route.query.type
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>
#main {
  background-color: #fafafa;

  .content {
    margin: auto;
    padding: .5rem .4rem;
    width: 12.0rem;
    background-color: #fff;

    > .title {
      text-align: center;
      line-height: .25rem;
      font-size: .18rem;
      font-weight: bold;
    }

    > .subTitle {
      line-height: .22rem;
      font-weight: bold;
    }

    > .thirdTitle {
      line-height: .2rem;
      font-size: .15rem;
      font-weight: bold;
    }

    > .section {
      line-height: .3rem;
      font-size: .14rem;
    }

    > .table {
      display: block;
      margin-top: .08rem;
      border: .01rem solid #ddd;

      > .table-title {
        display: block;
        height: .4rem;
        text-align: center;
        line-height: .4rem;
        border-bottom: .01rem solid #ddd;
        font-weight: bold;
      }

      > .table-row {
        clear: both;
        display: block;
        height: .4rem;
        border-bottom: .01rem solid #ddd;

        > span {
          float: left;
          text-align: center;
          height: .4rem;
          line-height: .4rem;
          border-left: .01rem solid #ddd;
        }

        > span:nth-child(1) {
          border-left: none;
        }
      }

      > .table-comment {
        display: block;
        padding: .03rem 0 .03rem .1rem;
        line-height: .26rem;

        > span {
          display: block;
        }
      }
    }

    > .hotline {
      height: .4rem;
      line-height: .4rem;
      font-size: .18rem;
      font-weight: bold;
    }

    > .mt8 {
      margin-top: .08rem;
    }

    > .mt16 {
      margin-top: .16rem;
    }

    > .mt24 {
      margin-top: .24rem;
    }

    > .mt32 {
      margin-top: .32rem;
    }
  }
}
</style>
